/** @jsx jsx */
import { jsx, Card, Box, Grid } from "theme-ui";
import React, { useEffect, useState } from "react";
import { Select } from "gatsby-plugin-hfn-profile/components/ui";
import { usePersist } from "gatsby-plugin-hfn-profile/globals";
import { navigate } from "gatsby";
import { useQueryParam, StringParam } from "use-query-params";
import DonationLayout from "../layout/DonationLayout";
import data from "../../data/landing.json";

require("bootstrap/dist/css/bootstrap.min.css");

function onClickNavigate(e) {
  const gotoUri = e.target.attributes.datapath.value;
  navigate(gotoUri);
}
const tabs = [
  "Online Donations",
  "Offline modes of Donation",
  "Recurring Donations",
];
const countryOption = [
  "-select country-",
  "india",
  "united states",
  "europe",
  "france",
  "canada",
  "kenya",
  "others",
];

const HomePage = () => {
  const [selectedCountry, setSelectedCountry] = usePersist(
    "donation-country",
    "-select country-"
  );
  const [selectedDonationType, setSelectedDonationType] = usePersist(
    "donation-type",
    "Online Donations"
  );
  const [responseData, setResponseData] = useState("");
  const [donationNote, setDonationNote] = useState("");
  const [sourceValue] = useQueryParam("source", StringParam);
  const [country] = useQueryParam("country", StringParam);

  const note = (key) => {
    let noteText = "";
    switch (key) {
      case "india_online_donations":
        noteText = `Donation to the above funds is eligible for 80G exemption under the Income Tax Act, India`;
        break;
      case "india_offline_modes_of_donation":
        noteText = `Donations can be accepted as Cheque, NEFT/RTGS, Recurring donations and ECS.`;
        break;
      default:
        break;
    }

    setDonationNote(noteText);
  };

  useEffect(() => {
    setDonationNote("");
  }, [selectedDonationType]);

  useEffect(() => {
    if (sourceValue) {
      localStorage.setItem("source", sourceValue);
    }
  }, [sourceValue]);

  useEffect(() => {
    if (country && selectedCountry === "-select country-") {
      setSelectedCountry(country.toLowerCase());
    }
  }, [country, setSelectedCountry, selectedCountry]);

  useEffect(() => {
    if (!selectedCountry || selectedCountry === "-select country-") {
      return;
    }
    const countryKey = selectedCountry.split(" ").join("_").toLowerCase();
    const donationTypeKey = selectedDonationType
      .split(" ")
      .join("_")
      .toLowerCase();
    const key = `${countryKey}_${donationTypeKey}`;
    note(key);
    setResponseData(data[key]);
  }, [selectedCountry, selectedDonationType]);

  return (
    <DonationLayout seoData={{ title: "Donations - Heartfulness Donations" }}>
      <div className="container">
        <div sx={{ textAlign: "center", padding: "40px 0px" }}>
          <h1>Donate for a Heartful Humanity </h1>
        </div>
        <p>
          Share in the work of bringing peace, compassion, happiness and harmony
          to people of all walks of life by providing financial support to
          Heartfulness programs conducted worldwide by Shri Ram Chandra Mission
          and Sahaj Marg Spirituality Foundation.Donation of any size make it
          possible for us to change lives by teaching the Heartfulness
          techniques.{" "}
          <span sx={{ marginTop: "6px" }}>
            These programs are conducted exclusively by volunteers, and the
            techniques are taught free of charge to participants. Our dedicated
            volunteers, many of whom are professionals, donate their time and
            talent and cover their own expenses. Your donation will support our
            programs in schools, universities and colleges, corporates,
            hospitals and villages in 130 countries, as well as to any
            interested groups and communities.
          </span>
        </p>

        <Card
          sx={{
            mt: "30px",
            bg: "#f5f5f5",
            border: "1px solid #e3e3e3",
            borderRadius: "4px",
            minHeight: "20px",
            padding: "19px",
          }}
        >
          <Grid gap={1} columns={[1, 2, 3]} sx={{ margin: "0 20px 0 20px" }}>
            {tabs.map((item) => (
              <Box
                onClick={() => setSelectedDonationType(item)}
                sx={{
                  ":hover": {
                    border:
                      selectedDonationType !== item && "1px solid #d3d5d2",
                    borderBottom: 0,
                    bg: "#dff2f6",
                  },
                  border: "1px solid transparent",
                  borderBottom: 0,
                  borderRadius: selectedDonationType === item && "4px 4px 0 0",
                  bg: selectedDonationType === item && "#dff2f6",
                  fontSize: ["18px", null, "20px"],
                  fontWeight: "bolder",
                  padding: "2px 25px",
                  color: selectedDonationType !== item && "#1da1f2",
                  flex: "auto",
                  cursor: "pointer",
                }}
                key={item}
              >
                {item}
              </Box>
            ))}
          </Grid>
          <Box
            sx={{
              bg: "#47c6e126",
              border: "1px solid lightgray",
              mt: 0,
              margin: "0 20px 20px",
              padding: "20px",
            }}
          >
            <p>Country of Residence:</p>
            <Box sx={{ width: ["160px", "250px", "300px"] }}>
              <Select
                sx={{
                  background: "white",
                  cursor: "pointer",
                  fontFamily: "sans-serif",
                  textTransform: "capitalize",
                }}
                name="country"
                className="form-control"
                value={selectedCountry}
                onChange={(e) => setSelectedCountry(e)}
                options={countryOption}
              />
            </Box>
          </Box>
          {selectedCountry && (
            <Box
              sx={{
                bg: "#47c6e126",
                border: "1px solid lightgray",
                margin: "20px",
                padding: "20px",
              }}
            >
              {selectedCountry === "-select country-" ? (
                <p>Please select country of Residence</p>
              ) : (
                <React.Fragment>
                  <p>Please select one of the following funds to Donate</p>
                  {responseData &&
                    responseData.map((item) => (
                      <div sx={{ pb: "1em", pl: "1em" }} key={item.path}>
                        <div
                          tabIndex={0}
                          role="button"
                          sx={{
                            ":hover": {
                              opacity: "0.8",
                              textDecoration: "underline",
                            },
                            width: "fit-content",
                            fontWeight: "bolder",
                            mt: "5px",
                            mb: "5px",
                            cursor: "pointer",
                          }}
                          datapath={item.path}
                          onClick={(e) => onClickNavigate(e)}
                          onKeyDown={(e) => onClickNavigate(e)}
                        >
                          {item.title}
                        </div>
                        <p sx={{ fontSize: "15px", color: "#808080" }}>
                          {item.description}
                        </p>
                      </div>
                    ))}
                </React.Fragment>
              )}
              {donationNote && (
                <div sx={{ mt: 3, fontSize: "15px", color: "#808080" }}>
                  <hr />
                  {donationNote}
                </div>
              )}
            </Box>
          )}
        </Card>
        <p sx={{ pb: "15em" }} />
      </div>
    </DonationLayout>
  );
};

export default HomePage;
